import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "./loader.css"; // Make sure to create and import the CSS file for the loader
import AOS from "aos";
import "aos/dist/aos.css";
const Register = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 800, // Adjust duration for quicker animations on mobile
      easing: "ease-in-out",
      mirror: true, // Ensure animations happen when scrolling back up
      offset: 50,
      once: false,
    });

    // Optional: Refresh AOS to detect newly added elements
    AOS.refresh();
  }, []);

  const [formData, setFormData] = useState({
    businessName: "",
    businessType: "",
    email: "",
    phoneNumber: "",
    businessRegistrationProof: null,
    businessRegistrationProofName: "",
    businessAddress: "",
    photo: null,
    photoName: "",
    utilityBill: null,
    utilityBillName: "",
  });

  const [loading, setLoading] = useState(false);
  const [previews, setPreviews] = useState({
    businessRegistrationProof: null,
    photo: null,
    utilityBill: null,
  });

  const handleInputChange = (event) => {
    if (event.target.type === "file") {
      const file = event.target.files[0];
      const fileName = event.target.name;
      setFormData({
        ...formData,
        [fileName]: file,
        [`${fileName}Name`]: file.name,
      });
      setPreviews({
        ...previews,
        [fileName]: URL.createObjectURL(file),
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const apiEndpoint = "https://contactapi.the-firstclass.club/register";
    const formDataToSend = new FormData();

    formDataToSend.append("businessName", formData.businessName);
    formDataToSend.append("businessType", formData.businessType);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phoneNumber", formData.phoneNumber);
    formDataToSend.append(
      "businessRegistrationProof",
      formData.businessRegistrationProof
    );
    formDataToSend.append("businessAddress", formData.businessAddress);
    formDataToSend.append("photoLocation", formData.photo);
    formDataToSend.append("utilityBill", formData.utilityBill);

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Registration failed");
      }

      const responseData = await response.json();
      toastr.success(responseData.message);

      // Reset the form after successful submission
      setFormData({
        businessName: "",
        businessType: "",
        email: "",
        phoneNumber: "",
        businessRegistrationProof: null,
        businessAddress: "",
        photo: null,
        utilityBill: null,
      });
      setPreviews({
        businessRegistrationProof: null,
        photo: null,
        utilityBill: null,
      });
    } catch (error) {
      toastr.error("Error occurred while registering. Please try again later.");
      console.error("Error occurred while registering:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Register</title>
      </Helmet>

      <div className="register-page">
        <div className="register-herosection">
          <div className="register-inside">
            <h3 className="register-title">Registration</h3>
            <p className="register-para" data-aos="fade-down">
              We create successful campaigns that are impactful, tangible, and
              result oriented. By crafting content around your members, we
              enhance your type of clientele and boost your venue's reputation
            </p>
          </div>
        </div>
        <div className="register-form-below">
          <div className="container">
            <div className="register-formInside">
              <form onSubmit={handleSubmit}>
                <div className="row gx-2 gx-md-4 gx-lg-5 gy-lg-4 gy-md-3 gy-2">
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="businessName">
                      Business Name
                    </label>
                    <input
                      type="text"
                      className="form-control register-input"
                      id="businessName"
                      name="businessName"
                      placeholder="Enter Business Name"
                      value={formData.businessName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="form-group  col-md-6">
                    <label className="form-lebels" htmlFor="businessType">
                      Business Type
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control register-input "
                        id="businessType"
                        name="businessType"
                        value={formData.businessType}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Business Type</option>
                        <option value="Staycation">Staycation</option>
                        <option value="Restaurant">Restaurant</option>
                        <option value="Night clubs">Night clubs</option>
                        <option value="Fitness and Wellness">
                          Fitness and Wellness
                        </option>
                        <option value="Beach Clubs">Beach Clubs</option>
                        <option value="Entertainment">Entertainment</option>
                      </select>

                      <img
                        className="down-arow-top"
                        src="/images/register/downaro.png"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control register-input"
                      id="email"
                      name="email"
                      placeholder="Enter Your Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="phoneNumber">
                      Phone number
                    </label>
                    <input
                      type="tel"
                      className="form-control register-input"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter Your Phone number"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label
                      className="form-lebels"
                      htmlFor="businessRegistrationProof"
                    >
                      Business Registration proof
                    </label>
                    <div className="position-relative">
                      <input
                        type="file"
                        className="form-control register-input upload-input"
                        placeholder="Business Registration proof"
                        id="businessRegistrationProof"
                        name="businessRegistrationProof"
                        onChange={handleInputChange}
                        required
                      />
                      <div className="form-control register-input dommy-input">
                        <img
                          className="upload-img"
                          src="/images/register/uploadfile.png"
                          alt="Upload"
                        />
                      </div>
                      {/* {previews.businessRegistrationProof && (
                        <div className="preview">
                          <img
                            src={previews.businessRegistrationProof}
                            className="previw-businessimage"
                            alt="Business Registration Proof Preview"
                          />
                        </div>
                      )} */}
                      {formData.businessRegistrationProofName && (
                        <p className="filename-txt">
                          {formData.businessRegistrationProofName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="businessAddress">
                      Enter address
                    </label>
                    <input
                      type="text"
                      className="form-control register-input"
                      id="businessAddress"
                      name="businessAddress"
                      placeholder="Enter Your business address"
                      value={formData.businessAddress}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="photo">
                      Upload photo
                    </label>
                    <div className="position-relative">
                      <input
                        type="file"
                        className="form-control browse-boxinput"
                        name="photo"
                        onChange={handleInputChange}
                        required
                      />
                      <div className="form-control browse-boxtop">
                        <img
                          className="cloud-img"
                          src="/images/register/cloud.png"
                          alt="Cloud"
                        />
                        <p className="files-text">Browse Files</p>
                        <p className="drag-text">
                          Drag and drop JPEG, PNG, files here
                        </p>
                      </div>
                      {previews.photo && (
                        <div className="image-preview">
                          <img
                            src={previews.photo}
                            className="view-image"
                            alt="Photo Preview"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="utilityBill">
                      Upload Utility Bill
                    </label>
                    <div className="position-relative">
                      <input
                        type="file"
                        className="form-control browse-boxinput"
                        name="utilityBill"
                        onChange={handleInputChange}
                        required
                      />
                      <div className="form-control browse-boxtop">
                        <img
                          className="cloud-img"
                          src="/images/register/cloud.png"
                          alt="Cloud"
                        />
                        <p className="files-text">Browse Files</p>
                        <p className="drag-text">
                          Drag and drop JPEG, PNG, files here
                        </p>
                      </div>
                      {/* {previews.utilityBill && (
                        <div className="preview">
                          <img
                            src={previews.utilityBill}
                            alt="Utility Bill Preview"
                          />
                        </div>
                      )} */}
                      {formData.utilityBillName && (
                        <p className="filename-txt">
                          {formData.utilityBillName}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="register-formBtn"
                  disabled={loading}
                >
                  {loading ? <div className="loader"></div> : "Register Now"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
// import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
// import { useForm } from "react-hook-form";
// import toastr from "toastr";
// import "toastr/build/toastr.min.css";
// import "./loader.css"; // Make sure to create and import the CSS file for the loader
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// const Register = () => {
//   const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
//   const [loading, setLoading] = useState(false);
//   const [previews, setPreviews] = useState({
//     businessRegistrationProof: null,
//     photo: null,
//     utilityBill: null,
//   });

//   useEffect(() => {
//     window.scroll({
//       top: 0,
//       left: 0,
//       behavior: "smooth",
//     });

//     AOS.init({
//       duration: 800,
//       easing: 'ease-in-out',
//       mirror: true,
//       offset: 50,
//       once: false,
//     });

//     AOS.refresh();
//   }, []);

//   const handleFileChange = (event) => {
//     const { name, files } = event.target;
//     const file = files[0];

//     if (file) {
//       setValue(name, file);
//       setPreviews((prev) => ({
//         ...prev,
//         [name]: URL.createObjectURL(file),
//       }));
//     }
//   };

//   const onSubmit = async (data) => {
//     setLoading(true);

//     const apiEndpoint = "https://contactapi.the-firstclass.club/register";
//     const formDataToSend = new FormData();

//     for (const key in data) {
//       formDataToSend.append(key, data[key]);
//     }

//     try {
//       const response = await fetch(apiEndpoint, {
//         method: "POST",
//         body: formDataToSend,
//       });

//       if (!response.ok) {
//         throw new Error("Registration failed");
//       }

//       const responseData = await response.json();
//       toastr.success(responseData.message);

//       reset();
//       setPreviews({
//         businessRegistrationProof: null,
//         photo: null,
//         utilityBill: null,
//       });
//     } catch (error) {
//       toastr.error("Error occurred while registering. Please try again later.");
//       console.error("Error occurred while registering:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <Helmet>
//         <title>Register</title>
//       </Helmet>

//       <div className="register-page">
//         <div className="register-herosection">
//           <div className="register-inside">
//             <h3 className="register-title">Registration</h3>
//             <p className="register-para" data-aos="fade-down">
//               We create successful campaigns that are impactful, tangible, and
//               result oriented. By crafting content around your members, we
//               enhance your type of clientele and boost your venue's reputation
//             </p>
//           </div>
//         </div>
//         <div className="register-form-below">
//           <div className="container">
//             <div className="register-formInside">
//               <form onSubmit={handleSubmit(onSubmit)}>
//                 <div className="row gx-2 gx-md-4 gx-lg-5 gy-lg-4 gy-md-3 gy-2">
//                   <div className="form-group col-md-6">
//                     <label className="form-lebels" htmlFor="businessName">
//                       Business Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control register-input"
//                       id="businessName"
//                       placeholder="Enter Business Name"
//                       {...register("businessName", { required: true })}
//                     />
//                     {errors.businessName && <span>This field is required</span>}
//                   </div>

//                   <div className="form-group col-md-6">
//                     <label className="form-lebels" htmlFor="businessType">
//                       Business Type
//                     </label>
//                     <div className="position-relative">
//                       <select
//                         className="form-control register-input"
//                         id="businessType"
//                         {...register("businessType", { required: true })}
//                       >
//                         <option value="">Select Business Type</option>
//                         <option value="Staycation">Staycation</option>
//                         <option value="Restaurant">Restaurant</option>
//                         <option value="Night clubs">Night clubs</option>
//                         <option value="Fitness and Wellness">Fitness and Wellness</option>
//                         <option value="Beach Clubs">Beach Clubs</option>
//                         <option value="Entertainment">Entertainment</option>
//                       </select>
//                       <img className="down-arow-top" src="/images/register/downaro.png" alt="Dropdown Arrow" />
//                       {errors.businessType && <span>This field is required</span>}
//                     </div>
//                   </div>

//                   <div className="form-group col-md-6">
//                     <label className="form-lebels" htmlFor="email">
//                       Email
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control register-input"
//                       id="email"
//                       placeholder="Enter Your Email"
//                       {...register("email", { required: true })}
//                     />
//                     {errors.email && <span>This field is required</span>}
//                   </div>

//                   <div className="form-group col-md-6">
//                     <label className="form-lebels" htmlFor="phoneNumber">
//                       Phone number
//                     </label>
//                     <input
//                       type="tel"
//                       className="form-control register-input"
//                       id="phoneNumber"
//                       placeholder="Enter Your Phone number"
//                       {...register("phoneNumber", { required: true })}
//                     />
//                     {errors.phoneNumber && <span>This field is required</span>}
//                   </div>

//                   <div className="form-group col-md-6">
//                     <label className="form-lebels" htmlFor="businessRegistrationProof">
//                       Business Registration proof
//                     </label>
//                     <div className="position-relative">
//                       <input
//                         type="file"
//                         className="form-control register-input upload-input"
//                         id="businessRegistrationProof"
//                         {...register("businessRegistrationProof", { required: true })}
//                         onChange={handleFileChange}
//                       />
//                       <div className="form-control register-input dommy-input">
//                         <img className="upload-img" src="/images/register/uploadfile.png" alt="Upload" />
//                       </div>
//                       {previews.businessRegistrationProof && (
//                         <p className="filename-txt">{previews.businessRegistrationProof}</p>
//                       )}
//                       {errors.businessRegistrationProof && <span>This field is required</span>}
//                     </div>
//                   </div>

//                   <div className="form-group col-md-6">
//                     <label className="form-lebels" htmlFor="businessAddress">
//                       Enter address
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control register-input"
//                       id="businessAddress"
//                       placeholder="Enter Your business address"
//                       {...register("businessAddress", { required: true })}
//                     />
//                     {errors.businessAddress && <span>This field is required</span>}
//                   </div>

//                   <div className="form-group col-md-6">
//                     <label className="form-lebels" htmlFor="photo">
//                       Upload photo
//                     </label>
//                     <div className="position-relative">
//                       <input
//                         type="file"
//                         className="form-control browse-boxinput"
//                         {...register("photo", { required: true })}
//                         onChange={handleFileChange}
//                       />
//                       <div className="form-control browse-boxtop">
//                         <img className="cloud-img" src="/images/register/cloud.png" alt="Cloud" />
//                         <p className="files-text">Browse Files</p>
//                         <p className="drag-text">Drag and drop JPEG, PNG, files here</p>
//                       </div>
//                       {previews.photo && (
//                         <div className="image-preview">
//                           <img src={previews.photo} className="view-image" alt="Photo Preview" />
//                         </div>
//                       )}
//                       {errors.photo && <span>This field is required</span>}
//                     </div>
//                   </div>

//                   <div className="form-group col-md-6">
//                     <label className="form-lebels" htmlFor="utilityBill">
//                       Upload Utility Bill
//                     </label>
//                     <div className="position-relative">
//                       <input
//                         type="file"
//                         className="form-control browse-boxinput"
//                         {...register("utilityBill", { required: true })}
//                         onChange={handleFileChange}
//                       />
//                       <div className="form-control browse-boxtop">
//                         <img className="cloud-img" src="/images/register/cloud.png" alt="Cloud" />
//                         <p className="files-text">Browse Files</p>
//                         <p className="drag-text">Drag and drop JPEG, PNG, files here</p>
//                       </div>
//                       {previews.utilityBill && (
//                         <p className="filename-txt">{previews.utilityBill}</p>
//                       )}
//                       {errors.utilityBill && <span>This field is required</span>}
//                     </div>
//                   </div>
//                 </div>
//                 <button type="submit" className="register-formBtn" disabled={loading}>
//                   {loading ? <div className="loader"></div> : "Register Now"}
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Register;
