import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Termcondition = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
    <Helmet>
        <title>
        Terms & Conditions
        </title>
      </Helmet>
      <div className="term-condition">
      <div className="terms-herosection">
        <div className="term-top">
          <div className="terms-inside">
            <h3 className="terms-title">Terms and Conditions</h3>
          </div>
        </div>
      </div>
      <div className="terms-data-below">
        <div className="container">
          <div className=" term-box-inside">
            <p className="term-subtext">
              Welcome to the First Class application (the "App"). By
              downloading, accessing, or using the App, you agree to be bound by
              these Terms and Conditions ("Terms"), so please read them
              carefully.
            </p>
            <h2 className="term-text1">1.0 Introduction</h2>
            <p className="term-subtext">
              1.1 The First Class App ("App") provides an online platform
              connecting influential individuals such as influencers,
              celebrities, models, and other personalities ("Members") with
              brands, venues, businesses, companies, and other entities
              ("Businesses") that offer various promotions, events, and other
              promotional opportunities ("Promotions").
            </p>
            <p className="term-subtext">
              1.2 Through the App, Members can browse, view details about, and
              accept promotions that they are interested in participating in.
              Businesses can use the App to create listings for their Promotions
              and offer those Promotions to Members that they want to target.
            </p>
            <p className="term-subtext">
              1.3 The First Class solely provides the technology platform that
              facilitates the connections between Members and Businesses. We do
              not employ or manage the Members or Businesses directly. Any
              interactions, agreements, negotiations, or arrangements regarding
              the Promotions take place directly between the Members and
              Businesses pursuant to their own independent understandings and
              legal agreements to which The First Class is not a party.
            </p>
            <p className="term-subtext">
              The core purpose of the First Class App is to serve as an
              intermediary platform that allows businesses to gain access to
              influential individuals to promote their offerings while allowing
              those influential Members to get notified of and choose which
              Promotions to accept based on their interests and availability.
              The First Class provides the technical capabilities for this
              ecosystem but does not control or involve itself in the specifics
              of each Promotion.
            </p>
            <h2 className="term-text1"> 2.0 Account Setup</h2>
            <p className="term-subtext">
              2.1 To use the App, you must create an account by registering your
              personal details with us. Providing your personal details
              constitutes your acceptance of these Terms, under which you
              acknowledge that you have rights but also obligations of which you
              should be cautious.
            </p>
            <p className="term-subtext">
              2.2 By agreeing to these Terms, you authorize us representatives
              to liaise with you, including contacting you via methods such as
              email and telephonic communications regarding your use of the App
              or queries you may have.
            </p>
            <p className="term-subtext">
              2.3 You will be required to provide us with certain personal
              particulars for our internal records pursuant to legal
              requirements. By accepting these Terms, you warrant that any
              personal details you provide are accurate to the best of your
              knowledge. Failure to keep your details up-to-date in line with
              changes may constitute a breach of these Terms.
            </p>
            <p className="term-subtext">
              2.4 By making use of this app, you confirm that you have read,
              understood, and agreed to abide by all terms and conditions.
            </p>
            <h2 className="term-text1"> 3.0 License and Restrictions</h2>

            <p className="term-subtext">
              3.1 Subject to your full compliance with all of these Terms, First
              Class grants you a limited, non-exclusive, non-transferable, and
              revocable license to access and use the First Class App solely for
              your own personal, non-commercial purposes. This license governs
              your use of the App and does not transfer or assign any
              intellectual property or other proprietary rights to you.
            </p>
            <p className="term-subtext">
              3.2 Except as expressly permitted by the intended functionality of
              the App as determined by us, you are strictly prohibited from
              engaging in any of the following restricted activities:
            </p>
            <p className="term-subtext">
              (a) Copying, modifying, creating derivative versions, reverse
              engineering, decompiling, disassembling, or redistributing the App
              or any part thereof;
            </p>
            <p className="term-subtext">
              (b) Removing, altering, obscuring or modifying any proprietary
              notices, labels, or intellectual property markings from the App;
            </p>
            <p className="term-subtext">
              (c) Using the App or any data/content therein for any commercial
              purposes or activities without first obtaining our express written
              consent;
            </p>
            <p className="term-subtext">
              (d) Using the App in any unlawful manner or in any way that
              violates these Terms or other applicable laws/regulations.
            </p>

            <p className="term-subtext">
              3.3 All rights related to the App that are not expressly granted
              to you through the limited license described in Section 3.1 are
              reserved by First Class. We retain full ownership and all
              intellectual property rights in the App. The limited license is
              revocable at any time at our sole discretion, in which case your
              right to access or use the App will immediately terminate. The
              restrictions in this section are implemented to protect our
              proprietary App, prevent unauthorized commercial exploitation,
              enforce lawful use, and maintain the intended functionality and
              user experience. Violations could result in legal liability in
              addition to license revocation. Let us know if you need any
              clarification on these important limitations.
            </p>

            <p className="term-subtext">
              3.4 Businesses listed on First Class should agree to collaborate
              with influencers listed on the First Class platform solely through
              the First Class Application only. Similarly, First Class
              influencers commit to collaborating with Businesses exclusively
              through the platform. Any direct communication or engagement
              outside of the First Class platform between Businesses and
              influencers listed on the First Class app is strictly prohibited.
              Additionally, Businesses must refrain from soliciting or accepting
              services from influencers listed on First Class outside of the
              platform. Both influencers and Businesses are expected to maintain
              professionalism at all times when using the First Class platform.
              First Class reserves the right to suspend or terminate the account
              of any influencer or Business found violating these Terms and
              Conditions.
            </p>

            <p className="term-subtext">
              3.5 To access in-app events, First Class influencers must meet
              specific requirements. Firstly, their Influence Account must be FC
              Verified, meaning their account should have a minimum of 3000
              followers. Verification involves submitting social media profile
              usernames, a selfie picture, and a 10-second video for
              authentication via the First Class App. Additionally, influencers
              need to switch to an Instagram Business Account and log in with
              Facebook (Meta) via the First Class App. This step ensures
              compliance with Instagram's rules and provides valuable insights
              for both influencers and businesses utilizing the app's
              functionalities.{" "}
            </p>
            <h2 className="term-text1">
              4.0 Member and Business Responsibilities
            </h2>
            <p className="term-subtext">
              {" "}
              4.1 The App facilitates independent transactions and interactions
              directly between Members and Businesses. The First Class acts
              solely as the provider of the technology platform. We are not
              involved in, do not control, or regulate, and disclaim all
              liability related to any interactions, agreements, dealings, or
              disputes between Members and Businesses regarding Promotions.
            </p>
            <p className="term-subtext">
              4.2 As a Member using the App, you are solely responsible for
              conducting yourself professionally and appropriately at all times
              when participating in Promotions. This includes strictly complying
              with all applicable rules, requirements, terms and conditions set
              by the Business for each specific Promotion. Violations of a
              business's provisions could result in the termination of your
              First Class account.{" "}
            </p>
            <p className="term-subtext">
              4.3 Businesses are operating independent businesses on the App
              platform. As a Business, you are solely responsible and liable for
              all aspects of the Promotions you offer through the App. This
              includes ensuring your Promotions and related activities comply
              fully with all applicable laws, regulations, rules, venue
              policies, and other governing terms. You must also take all
              appropriate safety, security, and risk management measures for
              your Promotions.{" "}
            </p>
            <p className="term-subtext">
              4.4 Under no circumstances may you use the App to engage in any
              unlawful, abusive, harassing, defamatory, objectionable, or other
              inappropriate conduct, whether towards First Class, other users,
              third parties, or otherwise. This is grounds for immediate action.{" "}
            </p>
            <p className="term-subtext">
              4.5 If you violate any of these responsibilities, The First Class
              reserves the right to suspend or permanently terminate your
              account and access to the App at our sole discretion. We maintain
              a zero-tolerance policy for violations. The terms in this section
              aim to clearly delineate the limited role of The First Class
              versus the independent responsibilities of Members and Businesses
              engaging in Promotions. Compliance with all applicable laws and
              terms is strictly required to participate on the App platform.
            </p>
            <p className="term-subtext">
              4.6 When First Class influencers attend an event via First Class
              and promote it on their Instagram platform, they should mention
              the First Class Instagram page @thefirstclassclub and include #FCC
              in their promotional post. First Class has all the rights to
              repurpose the promotional content.
            </p>
            <p className="term-subtext">
              4.7 Members who attend an event cannot participate in another
              event with the same business for at least two weeks from the
              initial attendance date.
            </p>

            <h2 className="term-text1">
              5.0 Service Descriptions and Payments
            </h2>
            <p className="term-subtext">
              5.1 To gain access to the Business features of the App and the
              ability to offer Promotions to Members, Businesses must first
              purchase a subscription plan through the App. Subscription plans
              and their respective features/capabilities are required for
              Businesses to utilize the App's promotional services.
            </p>
            <p className="term-subtext">
              5.2 Details about the different subscription plan options, such as
              pricing, features, payment terms, and any other relevant
              information will be clearly presented to you during the
              subscription purchase process in the website
              (www.the-firstclass.club). Before completing a subscription
              purchase, you should carefully review and ensure you understand
              the particulars of each plan.
            </p>
            <p className="term-subtext">
              5.3 By initiating a subscription purchase on the App, you
              authorize First Class to automatically bill you the subscription
              fees on a recurring basis according to the specific billing cycle
              of the plan you select (e.g. monthly, yearly, etc.). All
              subscription fees are final and non-refundable.
            </p>

            <p className="term-subtext">
              5.4 The First Class reserves the right, in its sole discretion, to
              modify or adjust the pricing, features, policies, and any other
              terms related to the subscription plans from time to time as it
              deems appropriate. You will be notified of any such changes prior
              to their effective date. The subscription-based model allows
              Businesses flexibility in choosing plan options suitable to their
              promotional needs and budgets. Recurring billing streamlines the
              process while preserving The First Class's ability to iterate on
              pricing and features. All subscription details are clearly
              disclosed upfront.
            </p>
            <h2 className="term-text1">
              6.0 Content and Intellectual Property
            </h2>
            <p className="term-subtext">
              6.1 The First Class App, including all features, functions, code,
              data, text, graphics, logos, and other content and materials
              comprising the App, is owned by First Class and/or its licensors
              and is protected by intellectual property laws, including
              copyright, trademark, patent, and trade secret laws. The App
              should be viewed as the proprietary property of First Class.
            </p>
            <p className="term-subtext">
              6.2 The App may display or contain links to third-party websites,
              applications, advertisements, promotions or other content not
              owned or controlled by First Class ("Third-Party Content"). First
              Class does not endorse and is not responsible or liable for any
              Third-Party Content accessed through the App.
            </p>
            <p className="term-subtext">
              6.3 By posting, uploading, submitting or otherwise providing any
              photos, videos, text, graphics, data, or other materials to the
              App related to Promotions ("Member Content"), you automatically
              grant to First Class a perpetual, irrevocable, worldwide,
              royalty-free, sublicensable and transferable license to host,
              cache, store, copy, reproduce, modify, edit, reformat, translate,
              publish, publicly display and distribute such Member Content for
              purposes of operating, providing and enhancing the App and
              Promotions.
            </p>

            <p className="term-subtext">
              6.4 You represent and warrant that for any Member Content you
              provide, you either own all intellectual property and other
              proprietary rights in such content, or you have all necessary
              rights, licenses, and/or permissions from the applicable rights
              holders to grant the license in Section 6.3. You further represent
              and warrant that any Member Content is truthful, accurate, and
              complies fully with these Terms. This section clarifies the
              intellectual property rights and ownership related to the App
              itself as well as any content provided through the App. It grants
              First Class necessary licenses for Member Content while putting
              legal responsibility on Members for that content's permissibility.
            </p>
            <h2 className="term-text1">7.0 Prohibited Conduct</h2>
            <p className="term-subtext">
              You must not take any of the following actions, which constitute
              prohibited conduct under these Terms:
            </p>
            <p className="term-subtext">
              a) Access or use the App to violate any applicable law or
              regulation. The App must be used only for lawful purposes.
            </p>
            <p className="term-subtext">
              b) Impersonate another person/entity, falsely claim an affiliation
              with us, or otherwise provide false or misleading information
              about your identity.
            </p>

            <p className="term-subtext">
              c) Attempt to gain unauthorized access to First Class accounts,
              data, systems or networks through hacking efforts or by any other
              means.
            </p>
            <p className="term-subtext">
              ) Use the App in a manner that could interfere with, disrupt, or
              prevent other users from being able to access and utilize the
              App's intended functionality.
            </p>
            <p className="term-subtext">
              e) Probe, test, or attempt to discover vulnerabilities, attack
              vectors, or break through any security measures protecting the
              App's systems and networks.
            </p>
            <p className="term-subtext">
              f) Take actions that directly or indirectly interfere with,
              overburden, or disturb our operations, servers, networks, or
              ability to provide and maintain the App.
            </p>

            <p className="term-subtext">
              g) Assist, instruct, or encourage others to engage in any of the
              prohibited conduct outlined in this section. Engaging in any
              prohibited conduct may result in termination of your access,
              banning from the App, and potential legal action. We maintain this
              strict policy to ensure the App operates securely and properly for
              all users.
            </p>
            <h2 className="term-text1">8.0 Disclaimer of Warranties</h2>
            <p className="term-subtext">
              8.1 The First Class App and all features and functions comprising
              the App are provided to you strictly on an "as is" and "as
              available' ' basis. First Class makes no representations or
              warranties of any kind, whether express, implied, or statutory,
              regarding the App or any content or services accessed through the
              App. We disclaim all warranties, representations, and conditions
              to the fullest extent permitted by law. This disclaimer includes,
              without limitation, any implied warranties of merchantability,
              fitness for a particular purpose, quiet enjoyment, accuracy,
              availability, non-infringement, or arising from a course of
              dealing or usage of trade. We do not warrant that the App will
              meet your requirements or expectations.
            </p>
            <p className="term-subtext">
              8.2 Furthermore, First Class does not warrant or make any
              representations that access to or use of the App will be
              uninterrupted, timely, secure, free of errors, viruses, or other
              harmful components, or that any defects or errors will be
              corrected. You acknowledge that there are inherent risks
              associated with using any internet-based applications and
              services.
            </p>
            <p className="term-subtext">
              8.3 You expressly agree that your access and use of the App is
              entirely at your own risk. First Class will not be liable for any
              direct, indirect, incidental, consequential, or punitive damages
              arising out of or related to your use or inability to use the App,
              including any damages to your device or equipment, or for any data
              or information stored therein. This disclaimer is a comprehensive
              effort to disclaim all possible warranties, whether express or
              implied by law, regarding any aspect of the App. The purpose is to
              make clear that the App is provided "as is" without any guarantees
              of quality, functionality, security or performance. All risk is
              assumed by end users.
            </p>

            <h2 className="term-text1">9.0 Limitation of Liability</h2>
            <p className="term-subtext">
              9.1 Under no circumstances shall First Class or any of its parent
              companies, subsidiaries, affiliates, officers, directors,
              employees, agents, partners or licensors be liable for any direct,
              indirect, incidental, consequential, punitive, special or
              exemplary damages, losses, expenses or liabilities whatsoever,
              including but not limited to damages for loss of profits, loss of
              goodwill, loss of use, loss of data, cost of procurement of
              substitute goods or services, business interruption or other
              intangible losses, arising out of or in any way related to:
            </p>
            <p className="term-subtext">
              (a) Your access, use, or inability to access or use the First
              Class App;
            </p>
            <p className="term-subtext">
              (b) Any reliance placed by you on content, promotions,
              information, or materials available through the App;
            </p>

            <p className="term-subtext">
              (c) Any transaction or dealing between you and any other App user,
              including Members and Businesses;
            </p>
            <p className="term-subtext">
              (d) Any unauthorized access or alteration of your account, data or
              transmissions;
            </p>
            <p className="term-subtext">
              (e) Any breach of these Terms by you or another party; or (f) Any
              other matter relating to the App or these Terms.
            </p>
            <p className="term-subtext">
              9.2 The limitation of liability set forth in this section shall
              apply to the fullest extent permitted by applicable law. Some
              jurisdictions may have laws that conflict with this provision, in
              which case the maximum limitation permitted by such law shall
              apply.
            </p>
            <p className="term-subtext">
              This provision limits First Class liability to the maximum extent
              allowable under the law. It prevents the company from being
              exposed to potentially ruinous damages from claims arising from
              use of the App. Reasonable limitations are common for online
              services.
            </p>

            <h2 className="term-text1">10.0 Indemnification section:</h2>
            <p className="term-subtext">
              Indemnification You agree to indemnify, defend and hold harmless
              First Class, its parent companies, subsidiaries, affiliates, and
              their respective directors, officers, employees, agents,
              representatives, partners, contractors, and licensors
              (collectively, the "Indemnified Parties") from and against any and
              all losses, liabilities, claims, demands, damages, expenses,
              fines, penalties, costs or fees (including reasonable attorneys'
              fees) arising from or relating to:
            </p>
            <p className="term-subtext">
              (a) Your access, use or misuse of the First Class App; (b) Any
              Member Content or other data or information you provide, submit or
              transmit through the App; (c) Your violation or breach of any
              term, condition, representation or warranty under these Terms of
              Service; (d) Your violation of any applicable laws, rules or
              regulations in connection with your use of the App; (e) Your
              negligence or wilful misconduct; (f) Any dispute or issue between
              you and any other user of the App, including any Member or
              Business; or (g) Your transactions, interactions or dealings with
              any third party involving Promotions or anything relating to your
              use of the App.
            </p>
            <p className="term-subtext">
              You agree to cooperate fully with the Indemnified Parties in the
              defence of any claim. First Class reserves the right, at its own
              expense, to assume the exclusive defence and control of any matter
              subject to indemnification and you agree not to settle any such
              matter without our prior written consent.
            </p>
            <p className="term-subtext">
              This indemnity provision is a critical protection measure to
              shield First Class and its related parties from potential
              third-party claims and liabilities arising from end users' actions
              when accessing or using the App. It makes users financially
              responsible for such claims. Robust indemnification terms are
              standard for online services.
            </p>
            <h2 className="term-text1">11.0 Term and Termination</h2>
            <p className="term-subtext">
              11.1 These Terms of Service constitute a binding legal agreement
              that goes into effect immediately upon your first access or use of
              the First Class App and will remain in full force and continue to
              apply for as long as you continue to access or use the App going
              forward. Your acceptance of these Terms is established by your
              initial and any subsequent access or use of the App.
            </p>

            <p className="term-subtext">
              11.2 The First Class reserves the absolute and unilateral right,
              at its sole discretion, to suspend or terminate your access to and
              use of the App, or any part thereof, at any time and for any
              reason whatsoever, or for no reason. We may terminate these Terms
              and discontinue providing you access to the App permanently or
              temporarily with or without prior notice.
            </p>
            <p className="term-subtext">
              11.3 Upon termination of these Terms for any reason, your license
              to access and use the App will immediately cease, and you must
              discontinue all use of and access to the App. However, certain
              provisions of these Terms by their nature will survive
              termination, including but not limited to Sections 6 (Content and
              Intellectual Property), 8 (Disclaimer of Warranties), 9
              (Limitation of Liability), 10 (Indemnification), 12
              (Modifications) and 13 (General). This section explains the
              perpetual, ongoing applicability of the Terms to your App usage
              until termination by First Class. It reserves our rights to
              terminate users' access at any time at our discretion. It
              specifies that certain key provisions like warranties, liability,
              indemnity, etc. survive even after termination.
            </p>
            <h2 className="term-text1">12.0 Modifications section</h2>
            <p className="term-subtext">
              12.1 First Class reserves the right, at its sole discretion, to
              modify, update, or change any part of these Terms of Service or
              the First Class App, including but not limited to adding,
              removing, or adjusting features, functionality, eligibility
              criteria, or imposing new limitations or restrictions. We may make
              such modifications at any time, without prior notice, and for any
              reason, we deem appropriate or necessary.
            </p>
            <p className="term-subtext">
              12.2 Any modifications, updates, or changes to these Terms or the
              App will become effective immediately upon being posted or
              implemented on the App. Your continued access or use of the App
              after any such modifications constitutes your binding acceptance
              of those modifications and your agreement to be bound by the Terms
              as revised. If you do not agree to any modifications, your sole
              recourse is to discontinue all use of the App.
            </p>
            <p className="term-subtext">
              12.3 We recommend that you review these Terms periodically to stay
              informed of any modifications. We may, but are not obligated to,
              communicate important modifications separately to you through the
              App or other means as we deem appropriate. This reserves The First
              Class right to modify the Terms or App features at any time
              without notice. It puts the onus on users to review the Terms
              periodically for updates and deems continued usage as acceptance
              of any new terms. This flexible approach allows us to adapt
              policies and functionality readily.
            </p>
            <h2 className="term-text1">13.0 Privacy Policy</h2>
            <p className="term-subtext">
              13.1 Your use of the First Class App is also governed by our
              Privacy Policy, which details how we collect, use, store, and
              protect your personal information. By using the App, you agree to
              the terms of our Privacy Policy. You can review the Privacy Policy
              at <NavLink target="_blank" to="/privacy-policy">www.the-firstclass.club/privacy-policy</NavLink> .
            </p>
            <h2 className="term-text1">14.0 Force Majeure</h2>
            <p className="term-subtext">
              A force majeure clause can protect both parties in case of
              unforeseen events.
            </p>
            <p className="term-subtext">
              14.1 First Class shall not be liable for any failure or delay in
              performance due to causes beyond its reasonable control, including
              but not limited to acts of God, natural disasters, war, terrorism,
              labor disputes, or governmental actions.
            </p>
            <h2 className="term-text1">15.0 Conclusion</h2>
            <p className="term-subtext">
              By accessing and using the First Class App, you agree to be bound
              by these Terms of Service, which establish the foundational rules
              governing your interactions with the platform. The First Class App
              serves as an intermediary technology platform, connecting
              influential individuals (Members) with Businesses for promotional
              opportunities. It is crucial to understand that The First Class
              only facilitates these connections and does not directly manage or
              oversee the interactions and agreements between Members and
              Businesses. These comprehensive Terms aim to provide clarity,
              establish expectations, and ensure a secure and efficient
              environment for all users of the First Class App. Your continued
              use of the App signifies your acceptance of these Terms and your
              commitment to complying with them. If you have any questions or
              require further clarification, please contact us at
              info@the-firstclass.club. Thank you for choosing First Class. We
              look forward to facilitating your successful connections and
              promotional endeavors through our platform.
            </p>
          </div>
        </div>
      </div>
    </div>
    
    </>
   
  );
};

export default Termcondition;
